
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NoImage from '../../Data/Image/noImage.png';
import mProduk from '../../Controller/produk';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from "react-loading-skeleton";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";


import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from '@material-ui/icons/FilterList';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { listTable as listTableProduk } from "../../interfaces/Produk";

import ModalDetail from './ModalDetail';
import ModalFilter from './ModalFilter';


const rowLoading = [] as any;
for (let i:number = 0; i <= 26; i++) {
  rowLoading.push(i);
}

const Produk = (props) => {
  const [listProduk, setListProduk] = useState<listTableProduk[]>([]);
  const [listDataOri, setListDataOri] = useState<listTableProduk[]>([]);
  const [isLoadProduk, setisLoadProduk] = useState<boolean>(true);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [Selected, setSelected] = useState<any>({});
  const [selectedKategori, setSelectedKategori] = useState<string>('');
  const [selectedSubkategori, setSelectedSubkategori] = useState<string>('');
  const [textCari, setTextCari] = useState<string>("");
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  useEffect(() => {
    if (isLoadProduk) {
      mProduk
        .listProdukAll()
        .then((resp:any) => {
          setListProduk(resp);
          setListDataOri(resp);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => setisLoadProduk(false));
    }

    return () => {

    }
  }, [isLoadProduk])

  useEffect(() => {
    if (selectedKategori) {
      const dataFilter = listDataOri
        .filter((f) => f.namakategori === selectedKategori)
        .filter((f2) => {
          if (selectedSubkategori) {
            return f2.namasubkategori === selectedSubkategori;
          } else return f2;
        });
      setListProduk(dataFilter);
    }
    return () => {

    }
  }, [selectedKategori, selectedSubkategori, listDataOri])

  const handleCari = useCallback(e => {
      const val = e.target.value;
      setTextCari(val);
      if (val) {

        const cari = val.split(" ");
        let hasil = listDataOri;
        cari.map(l => {
          return (hasil = hasil.filter((el:any) => {
            return el.gabung.toUpperCase().includes(l.toUpperCase());
          }));
        });
        setListProduk(hasil);
      } else {
        setListProduk(listDataOri);
      }
    },
    [listDataOri]
  );

  const handleOpenDetail = (data:any) => {
    setIsOpenModal(true);
    setSelected(data);
  }

  const renderProduk = useMemo(() => {
    return (
      <Container style={{ marginTop: 10 }}>
        {isLoadProduk ? (
          <Row className="gridStokMobile ">
            {rowLoading.map((el, index:number) => {
                return (
                  <Col xs={4} md={4} lg={3} className="mgTop10" key={index}>
                    <div className="items-img"
                    >
                      <Skeleton height={60} />

                    </div>
                  </Col>
                )
              })
            }
          </Row>
        ) : (
          <Row className="gridStokMobile ">
            {
              listProduk.map((el, index:number) => {
                const image = el.lokasifotoonline ? el.lokasifotoonline : NoImage;
                return (
                  <Col xs={4} md={4} lg={3} className="mgTop10" key={index+1}>
                    <div className="items-img"
                      onClick={handleOpenDetail.bind(this, el)}
                    >
                      <LazyLoadImage
                        src={image}
                        effect="blur"
                        alt="Avatar"
                        className="itemscard img"
                        style={{
                          cursor: "pointer",
                          width: "100%"
                        }}
                      />
                      <div>

                        <div
                          className="file btn btn-lg btn-primary tangan csSizeBtn"
                          style={{ cursor: "pointer", display: "flex", flexDirection: "column" }}
                        >
                          <div>
                            <span className="file-text-item">{el.namabarang}</span>
                          </div>
                        </div>


                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        )}
        <Row>
          <Col className='bgB60'></Col>
        </Row>
      </Container>
    );
  }, [isLoadProduk, listProduk]);

  const renderOpenDetail = useMemo(() => {
    if (isOpenModal) {
      return (<ModalDetail open={isOpenModal} setOpen={setIsOpenModal} Selected={Selected} />)
    } else return null;
  }, [isOpenModal, Selected]);

  const renderOpenFilter = useMemo(() => {
    if (isOpenFilter) {
      return (<ModalFilter open={isOpenFilter} setOpen={setIsOpenFilter} setSelectedKategori={setSelectedKategori} setSelectedSubkategori={setSelectedSubkategori} listDataOri={listDataOri} selectedKategori={selectedKategori} selectedSubkategori={selectedSubkategori} />)
    } else return null;
  }, [isOpenFilter, selectedKategori, selectedSubkategori, listDataOri]);

  return (
    <React.Fragment>
      <Row className={`fixed-top ${isScrolled ? 'scrolled' : ''}`}>
        <Col xs={6} md={6}>
          <Button
            onClick={() => setisLoadProduk(true)}
            style={{ marginTop: "5px" }}
            disabled={isLoadProduk}
            variant="contained"
            color="secondary"
          >
            <ReplayIcon />
          </Button>
          <Button
            onClick={() => setIsOpenFilter(true)}
            style={{ marginTop: "5px", marginLeft: 3, backgroundColor: '#009bde', marginRight: 3 }}
            disabled={isLoadProduk}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </Button>
        </Col>
        <Col xs={6} md={2}>
          {selectedKategori && <p className="titleFilter" style={{ marginTop: 5 }}> <PlaylistAddCheckIcon /> {selectedKategori}</p>}
          {selectedSubkategori && <p className="titleFilter" style={{ marginTop: -7 }}> <PlaylistAddCheckIcon /> {selectedSubkategori}</p>}
        </Col>
        <Col xs={12} md={4}>
          <TextField
            disabled={isLoadProduk}
            onChange={handleCari}
            value={textCari}
            fullWidth
            label="Search Produk"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Col>
      </Row>
      {renderProduk}
      {renderOpenDetail}
      {renderOpenFilter}
    </React.Fragment>
  )
}

export default Produk;