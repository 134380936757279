import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthContext from '../context/auth-context'
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import StoreIcon from '@material-ui/icons/Store';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from "react-router-dom";
import Company from "../settings/Company";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 99
    },
}));

const MyNavbar = (props) => {
    let history = useHistory();
    const { userLogin } = useContext(AuthContext);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [value, setValue] = useState('home');

    const logOut = () => {
        props.onLogout()
        handleClose()
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfile = () => {
        history.push(`/akun`)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`/${newValue}`)
    };
    useEffect(() => {
        const path = props.pathname
        if (path === "/" || path === "/home") {
            return setValue("home")
        } else if (path === "/akun") {
            return setValue("akun")
        } else if (path === "/riwayat") {
            return setValue("riwayat")
        } else if (path === "/produk") {
            return setValue("produk")
        } 
        return () => {

        }
    }, [props.pathname]);

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar position="static">
                    <Typography variant="h6" className={classes.title}>{Company.COMPANY_NAME}</Typography>
                    {props.isLogin &&
                        <>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleMenu}
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleProfile}><AccountCircle color="inherit" className="marginMenuItem"/> Profile (<i>{userLogin}</i>)</MenuItem>
                                <MenuItem onClick={logOut} className="colorRed marginMenuItem"> <ExitToAppIcon />Log Out</MenuItem>
                            </Menu>
                        </>
                    }
                </Toolbar>
            </AppBar>

            <BottomNavigation value={value} onChange={handleChange} className={classes.stickToBottom} showLabels>
                <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
                <BottomNavigationAction label="Produk" value="produk" icon={<StoreIcon />} />
                <BottomNavigationAction label="Riwayat" value="riwayat" icon={<LoyaltyIcon />} />
                <BottomNavigationAction label="Akun" value="akun" icon={<AccountCircleIcon />} />
            </BottomNavigation>

        </React.Fragment>
    );
}
export default MyNavbar;
