import GQL from '../settings/GQL';
import Company from "../settings/Company";
import { listTable} from "../interfaces/Produk";

const Riwayat = {
  listRiwayat: (clientID:string, token:string) => {
    return new Promise<listTable[]>((resolve, reject) => {
      const query = `
                    query{
                        listRiwayatTrans_memberApp(perusahaanID: ${Company.COMPANY_ID}){
                            notrans
                            tanggal
                            namabarang
                            harga
                            listGambar{
                              ImageName
                              IsMain
                          }
                        }
                    }
                `
      GQL(query,clientID, token).then(resp => {
        if (resp.errors) return (reject(resp.errors));;
        if (resp && resp.data && resp.data.listRiwayatTrans_memberApp) {
          const data:any = [];
          resp.data.listRiwayatTrans_memberApp.map(
            (el) => {
              let gabung = Object.values(el).join('');
              let lokasifotoonline = null;
              if (el.listGambar && el.listGambar.length > 0) {
                const listMain = el.listGambar
                  .filter((el) => el.IsMain)
                  .sort((a, b) => a.ID - b.ID);
                if (listMain.length > 0)
                  lokasifotoonline = listMain[0].ImageName;
                else {
                  const [gambar] = el.listGambar.sort((a, b) => a.ID - b.ID);
                  lokasifotoonline = gambar.ImageName;
                }
              }
              return data.push({
                ...el,
                gabung,
                lokasifotoonline,
              });
            }
          );
          return resolve(data);
        } else {
          return (resolve([]));
        }
      }).catch(err => reject(err));
    })
  },
  
}
export default Riwayat;