
import React, { useEffect, useState, useMemo } from 'react'
import { Carousel } from 'react-bootstrap';
//import AuthContext from '../../context/auth-context'
import { Container, Row, Col } from 'reactstrap';
import NoImage from '../../Data/Image/noImage.png';
import mHome from '../../Controller/home';
import mProduk from '../../Controller/produk';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import ModalDetail from '../Produk/ModalDetail';
import { listTable as listTableProduk } from "../../interfaces/Produk";
import { listTable as listTableSlider } from "../../interfaces/Home";

const rowLoading = [] as any;
for (let i:number = 0; i <= 14; i++) {
  rowLoading.push(i);
}

const Home = (props:any) => {
 // const { clientID, token } = useContext(AuthContext);
  const [listSlider, setListSlider] = useState<listTableSlider[]>([]);
  const [listProduk, setListProduk] = useState<listTableProduk[]>([]);
  
  const [isLoadProduk, setisLoadProduk] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [Selected, setSelected] = useState<any>({});


  useEffect(() => {
    mHome
      .listSlider()
      .then((resp:any) => {
        setListSlider(resp);
      })
      .catch(err => {
        console.error(err);
      });

    return () => {

    }
  }, []);

  useEffect(() => {
    if (isLoadProduk) {
      mProduk
      .listProdukPopuler()
      .then((resp:any) => {
        setListProduk(resp);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setisLoadProduk(false));

    return () => {

    }
  }
  }, [isLoadProduk]);

 

  const handleOpenDetail = (data) => {
    setIsOpenModal(true);
    setSelected(data);
  }
  const renderSlider = useMemo(() => {
    return (
      <Row>
        <div className="content-full mgTopMin5">
          <Carousel>
            {listSlider.map((el:any, i) => (
              <Carousel.Item key={i}>
                <img
                  className="d-block w-100 img-slide"
                  src={el.linkURL || NoImage}
                  alt="Makanan 1"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Row>
    )
  }, [listSlider])

  const renderProdukPopuler = useMemo(() => {
    return (
      <Container style={{ marginTop: 10 }}>
        <Row className="mgLRMin30">
          <Col xs={12}>
            <h4 className='text-center'>Produk Populer</h4>
          </Col>
        </Row>
        {isLoadProduk ? (
          <Row className="gridStokMobile ">
            {rowLoading.map((el:any, index:number) => {
                return (
                  <Col xs={4} md={4} lg={3} className="mgTop10" key={index}>
                    <div className="items-img">
                      <Skeleton height={60} />
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        ) : (
          <Row className="gridStokMobile ">
            {listProduk.map((el:any, index:number) => {
                const image = el.lokasifotoonline ? el.lokasifotoonline : NoImage;
                return (
                  <Col xs={4} md={4} lg={3} className="mgTop10" key={index}>
                    <div className="items-img"
                      onClick={handleOpenDetail.bind(this, el)}
                    >
                      <LazyLoadImage
                        src={image}
                        effect="blur"
                        alt="Avatar"
                        className="itemscard img"
                        style={{
                          cursor: "pointer",
                          width: "100%"
                        }}
                      />
                      <div>

                        <div
                          className="file btn btn-lg btn-primary tangan csSizeBtn"
                          style={{ cursor: "pointer", display: "flex", flexDirection: "column" }}
                        >
                          <div>
                            <span className="file-text-item">{el.namabarang}</span>
                          </div>
                        </div>


                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        )}
        <Row>
          <Col className='bgB60'></Col>
        </Row>
      </Container>
    );
  }, [isLoadProduk, listProduk]);

  const renderOpenDetail = useMemo(() => {
    if (isOpenModal) {
      return (<ModalDetail open={isOpenModal} setOpen={setIsOpenModal} Selected={Selected} />)
    } else return null;
  }, [isOpenModal, Selected]);

  
  return (
    <>
      {renderSlider}
      {renderProdukPopuler}
      {renderOpenDetail}
    </>
  )
}

export default Home;