import React, { useCallback, useState, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import NoImage from '../../Data/Image/noImage.png';
import numberWithCommas from "reactnumberwithcommas";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const DetailImage = (props) => {
  const classes = useStyles();
  const { Selected } = props;
  const [openPopUpGambar, setOpenPopUpGambar] = useState(false);
  const [openPopUpGambarSingle, setOpenPopUpGambarSingle] = useState(false);
  const [SelectImage, setSelectImage] = useState({});
  const [SelectImageSingle, setSelectImageSingle] = useState({});

  const handleClose = () => {
    props.setOpen(false);
  };

  const ShowImage = useCallback((namabarang, listGambar) => {
    if (listGambar?.length > 0) {
      const images = listGambar.map(el => {
        let isValid = el.ImageName;
        return {
          url: isValid ? el.ImageName : NoImage,
          title: namabarang
        };
      });
      if (listGambar?.length === 1) {
        setSelectImageSingle(listGambar[0]?.ImageName);
        setOpenPopUpGambarSingle(true);
      } else {
        setSelectImage(images);
        setOpenPopUpGambar(true);
      }
    }
  }, []);

  const HiddenImage = () => {
    setOpenPopUpGambar(false);
  };

  const HiddenImageSingle = () => {
    setOpenPopUpGambarSingle(false);
  };

  const renderPopUpImage = useMemo(() => {
    if (openPopUpGambar) {
      return (
        <Lightbox images={SelectImage} onClose={HiddenImage} />
      )
    } else if (openPopUpGambarSingle) {
      return (
        <Lightbox image={SelectImageSingle} onClose={HiddenImageSingle} />
      )
    } else return null
  }, [openPopUpGambar, openPopUpGambarSingle, SelectImage, SelectImageSingle]);

  return (
    <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Close
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <LazyLoadImage
          style={{
            cursor: "Zoom-In",
            width: "100%"
          }}
          src={Selected?.listGambar && Selected.listGambar[0] ? Selected.listGambar[0].ImageName : NoImage}
          alt={Selected.namabarang}
          onClick={ShowImage.bind(this, Selected.namabarang, Selected.listGambar)}
          className={classes.image} />
        <div className={classes.description}>
          <p>
            Kode Barang: <b>{Selected.kodebarang}</b>
          </p>
          <p>
            Nama Barang: <b>{Selected.namabarang}</b>
          </p>
          <p>
            Merk: <b>{Selected.namamerk}</b>
          </p>
          <p>
            Deskripsi: <b>{Selected.deskripsi}</b>
          </p>
          <p>
            Harga: <b>{numberWithCommas(Selected.jualidr)}</b>
          </p>
        </div>
        {renderPopUpImage}
      </div>
    </Dialog>
  );
};

export default DetailImage;

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      padding: '70px 20px 0px 20px',
    },
    image: {
      maxHeight: '75vh',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '50%',
      },
    },
    description: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
  })
);


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

