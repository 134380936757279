import React, { useEffect, useReducer, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context';
import { useCookies } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Login from './Components/Auth/Login/Login';
import Register from './Components/Auth/Register/Register';
import Aktivasi from './Components/Auth/Aktivasi';
import ResetPassword from './Components/Auth/ResetPassword';
import Navbar from './Navbar/Navbar';
import GQL from './settings/GQL';
import DialogInfo from './Dialog/DialogInfo';
import Home from './Components/Home';
import Produk from './Components/Produk';
import Riwayat from './Components/Riwayat';
import Akun from './Components/Akun';
import Company from "./settings/Company";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        width: window.innerWidth - 200
    },
}));

const initialValue = {
    token: null,
    clientID: null,
    clientID2: null,
    userLogin: null,
    hp: null,
    email: null,
    isCookieLoad: false,
    isLogin: false
}

const loginReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                token: action.token,
                clientID: action.clientID,
                clientID2: action.clientID2,
                userLogin: action.userLogin,
                email: action.email,
                hp: action.hp,
                isLogin: true
            }
        case "LOGOUT":
            return {
                ...state,
                userLogin: null,
                token: null,
                clientID: null,
                clientID2: null,
                email: null,
                hp: null
            }
        case "COOKIE":
            return {
                ...state,
                token: action.token,
                clientID: action.clientID,
                clientID2: action.clientID2,
                userLogin: action.userLogin,
                email: action.email,
                hp: action.hp,
                isCookieLoad: true
            }
        default: throw new Error("Invalid Action");
    }
}

function App(props) {
    const [state, dispatch] = useReducer(loginReducer, initialValue)
    const [cookies, setCookie, removeCookie] = useCookies([`${Company.COMPANY}-delivery`]);
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const classes = useStyles();
    useEffect(() => {
        var render = true;
        if (cookies[`${Company.COMPANY}-memberApp-auth`]) {
            const { clientID, clientID2, token, userLogin, email, hp } = cookies[`${Company.COMPANY}-memberApp-auth`] || { clientID: null, clientID2: null, token: null, userLogin: null, email: null, hp: null };
            if (render) {
                //console.log(clientID,token);
                dispatch({ type: "COOKIE", clientID, clientID2, token, userLogin, email, hp })
            }
        } else {
            dispatch({ type: "COOKIE", clientID, clientID2, token })

        }
        return () => {
            render = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies[`${Company.COMPANY}-memberApp-auth`]]);

    const login = (username, password) => {
        const userLogin = username
        const query = `
                        query{
                            loginUserMemberApp(perusahaanID:${Company.COMPANY_ID}, email:"${username}",password:"${password}"){
                                clientID
                                token
                                email
                                nohp
                            }
                        }
                        `
        GQL(query).then(res => {
            if (res.errors) throw res.errors;
            if (res.data.loginUserMemberApp) {
                const token = res.data.loginUserMemberApp.token
                const clientID = res.data.loginUserMemberApp.clientID
                const clientID2 = res.data.loginUserMemberApp.clientID
                const email = res.data.loginUserMemberApp.email
                const hp = res.data.loginUserMemberApp.nohp
                setCookie(`${Company.COMPANY}-memberApp-auth`, { clientID, clientID2, token, userLogin, email, hp }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
                dispatch({ type: "LOGIN", clientID, clientID2, token, userLogin, email, hp })
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${res.data.loginweb.info}<p/>`)
            }
        }).catch(err => {
            if (err && err[0].message) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err[0].message}<p/>`)
            } else {
                setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
            }
        })
    }

    const logout = () => {
        const query = `
                mutation {
                  logoutUserMemberApp(perusahaanID:${Company.COMPANY_ID})
                }
            `
        GQL(query, state.clientID, state.token).then(resp => {
            if (resp.errors) throw resp.errors;
            if (resp.data && resp.data.logoutUserMemberApp) {
            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>Gagal Logout<p/>`)
            }
        }).catch(err => {
            if (err[0].message) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err[0].message}<p/>`)
            } else {
                setDialogtext(`<p>Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti.<p/>`)
            }
        }).finally(() => {
            const clientID = null, clientID2 = null, token = null, userLogin = null, email = null, hp = null
            setCookie(`${Company.COMPANY}-memberApp-auth`, { clientID, clientID2, token, userLogin, email, hp }, { maxAge: 30 * 24 * 60 * 60, path: '/' })
            removeCookie(`${Company.COMPANY}-memberApp-auth`)
            dispatch({ type: "LOGOUT" })
        })
    }

    const { clientID, clientID2, token, isCookieLoad, isLogin, userLogin, email, hp } = state;

    //console.log(state)
    return (
        <Router>
            <React.Fragment>
                <AuthContext.Provider
                    value={{
                        token,
                        clientID,
                        clientID2,
                        login,
                        logout,
                        userLogin,
                        email,
                        hp
                    }}
                >
                    <div className={classes.root} >
                        <CssBaseline />
                        <Navbar onLogout={logout} isLogin={(token && clientID)} pathname={window.location.pathname} />
                        <main className={classes.content}>
                            <Switch>
                                <Route path="/aktivasi/:salt" component={Aktivasi} />
                                <Route path="/reset/:email/:salt" component={ResetPassword} />
                                {(clientID && token && isCookieLoad) &&
                                    <React.Fragment>
                                        <Route path="/" exact component={Home} />
                                        {/* TODO untuk profil dan password */}

                                        <Route path="/Akun/" component={Akun} />
                                        <Route path="/riwayat/" exact component={Riwayat} />
                                        <Route path="/produk/" exact component={Produk} />
                                        <Route path="/home/" exact component={Home} />
                                        {isLogin && <Redirect from="/home/" to="/" exact />}
                                    </React.Fragment>

                                }
                                {((!clientID || !token) && isCookieLoad) &&
                                    (
                                        <React.Fragment>
                                            <Route path="/login/" component={Login} />
                                            <Route path="/register/" component={Register} />
                                            <Route path="/Akun/" component={Login} />
                                            <Route path="/home/" exact component={Home} />
                                            <Route path="/produk/" exact component={Produk} />
                                            <Route path="/riwayat/" exact component={Login} />
                                            <Redirect to="/home/" />

                                        </React.Fragment>
                                    )
                                }

                            </Switch>
                        </main>
                    </div>
                </AuthContext.Provider>
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
            </React.Fragment>

        </Router>
    );
}

export default App;
