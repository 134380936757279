import GQL from '../settings/GQL';
import Company from "../settings/Company";
import { formUbahPassword,formUbahProfile,detailProfile } from "../interfaces/Akun";

const auth = {
    register: (email:string, namaLengkap:string, nohp:string, password:string, alamat:string) => {
        return new Promise((resolve, reject) => {
            const query = `
                        mutation{
                        registerUserMemberApp(perusahaanID: ${Company.COMPANY_ID}, 
                            input:{
                                email: "${email}"
                                nohp: "${nohp}"
                                Namacust: "${namaLengkap}"
                                password: "${password}"
                                alamat: "${alamat}"
                        })
                        }
                    `
            GQL(query, null, null).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.registerUserMemberApp) {
                    return (resolve(resp.data.registerUserMemberApp));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
    userDetail: (clientID:string, token:string,userLogin:string) => {
        return new Promise((resolve, reject) => {
            const query = `
                        query{
                            userDetail(username:"${userLogin}"){
                                urut
                                 username
                                 password
                                 salt
                                 nama
                                 alamat
                                 dob
                                 gender
                                 createdOn
                                 email
                             }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.userDetail != null) {
                    return (resolve(resp.data.userDetail));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
    detailUserUserMemberApp: (clientID:string, token:string,email:string) => {
        return new Promise<detailProfile>((resolve, reject) => {
            const query = `
                        query{
                            detailUserUserMemberApp(perusahaanID: ${Company.COMPANY_ID},email:"${email}"){
                                id
                                email
                                Namacust
                                alamat
                                nohp
                             }
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.detailUserUserMemberApp != null) {
                    return (resolve(resp.data.detailUserUserMemberApp));
                } else {
                    return (reject(new Error("Gagal mendapatkan detail data")))
                }
            }).catch(err => reject(err));
        })
    },
    updateProfile: (clientID:string,token:string,id:number,email:string, namaLengkap:string, nohp:number, alamat:string) => {
        return new Promise<formUbahProfile>((resolve, reject) => {
            const query = `
                        mutation{
                            updateUserMemberApp(perusahaanID: ${Company.COMPANY_ID}, 
                            input:{
                                id: "${id}"
                                email: "${email}"
                                nohp: "${nohp}"
                                Namacust: "${namaLengkap}"
                                alamat: "${alamat}"
                        })
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.updateUserMemberApp) {
                    return (resolve(resp.data.updateUserMemberApp));
                } else {
                    return (reject(new Error("Gagal menyimpan data")))
                }
            }).catch(err => reject(err));
        })
    },
    resetPassword: (clientID:string,token:string ,email:string, password:string) => {
        return new Promise<formUbahPassword>((resolve, reject) => {
            const query = `
                        mutation{
                            updatePasswordUserMemberApp(perusahaanID: ${Company.COMPANY_ID}, email: "${email}", password: "${password}")
                        }
                    `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp && resp.data && resp.data.updatePasswordUserMemberApp) {
                    return (resolve(resp.data.updatePasswordUserMemberApp));
                } else {
                    return (reject(new Error("Gagal update password")))
                }
            }).catch(err => reject(err));
        })
    },
}

export default auth;