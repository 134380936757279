import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import DialogInfo from '../../Dialog/DialogInfo'
import GQL from '../../settings/GQL';



const Aktivasi = (props) => {
    const salt = props.match.params.salt
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()

    useEffect(() => {
        const query = `
        mutation{
            aktivasiUser(kode:"${salt}")
          }
        `
        GQL(query, null, null).then(res => {
            if (res.errors) throw res.errors;
            setOpenConfirmInfo(true)
            setDialogtext(`<p>Akun Anda berhasil diaktifkan<p/>`)
            setDialogUrl(`/login/`)
        }).catch(err => {
            setOpenConfirmInfo(true)
            setDialogtext(`<p>Akun anda tidak dapat di aktifkan saat ini. Harap coba lagi nanti !<p/>`)
        })
        return () => {

        };
    }, [salt])
    return (
        <Container>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
        </Container>
    );
}

export default Aktivasi;