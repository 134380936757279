import Company from "./Company";
import axios from "axios";

const GQLFetch = (query, clientID, token) => {


  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  if (clientID) headers["X-API-CLIENT-ID"] = clientID;
  if (token) headers["X-API-TOKEN"] = token;
  return axios({
    method: "post",
    url: `${Company.URL_API}/graphql/`,
    headers,
    data: {
      query: query
    }
  })
    .then(res => res.data)
    .catch(error => {
      if (error.toString() === "Error: Network Error") {
        // alert(
        //   "Cek koneksi jaringan anda, jaringan anda sedang tidak ada atau tidak stabil"
        // );
        window.location.reload();
      }
      if (error.response && error.response.status === 401) {
        window.location.href = "/login";
      }
      // else window.location.reload();
    });
}
export default GQLFetch;