
import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react'
import AuthContext from '../../context/auth-context'
import { Row } from 'react-bootstrap';
import mRiwayat from '../../Controller/riwayat';
import Skeleton from "react-loading-skeleton";
import numberWithCommas from "reactnumberwithcommas";
import NoImage from '../../Data/Image/noImage.png';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import moment from "moment-timezone";
import { listTable as listTableRiwayat } from "../../interfaces/Riwayat";

const rowLoading = [] as any;
for (let i:number = 0; i <= 14; i++) {
    rowLoading.push(i);
}

const Riwayat = (props:any) => {
    const { clientID, token } = useContext<any>(AuthContext);
    const [listRiwayat, setListRiwayat] = useState<listTableRiwayat[]>([]);
    const [isLoadRiwayat, setisLoadRiwayat] = useState<boolean>(true);

    const [openPopUpGambar, setOpenPopUpGambar] = useState<boolean>(false);
    const [openPopUpGambarSingle, setOpenPopUpGambarSingle] = useState<boolean>(false);
    const [SelectImage, setSelectImage] = useState<any>({});
    const [SelectImageSingle, setSelectImageSingle] = useState<any>({});

    useEffect(() => {
        if (isLoadRiwayat) {
            mRiwayat
                .listRiwayat(clientID, token)
                .then((resp:any) => {
                    setListRiwayat(resp)
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setisLoadRiwayat(false));
        }

        return () => {

        }
    }, [isLoadRiwayat, clientID, token])

    const ShowImage = useCallback((namabarang, listGambar) => {
        if (listGambar?.length > 0) {
            const images = listGambar.map(el => {
                let isValid = el.ImageName;
                return {
                    url: isValid ? el.ImageName : NoImage,
                    title: namabarang
                };
            });
            if (listGambar?.length === 1) {
                setSelectImageSingle(listGambar[0]?.ImageName);
                setOpenPopUpGambarSingle(true);
            } else {
                setSelectImage(images);
                setOpenPopUpGambar(true);
            }
        }
    }, []);

    const HiddenImage = () => {
        setOpenPopUpGambar(false);
    };

    const HiddenImageSingle = () => {
        setOpenPopUpGambarSingle(false);
    };

    const renderPopUpImage = useMemo(() => {
        if (openPopUpGambar) {
            return (
                <Lightbox images={SelectImage} onClose={HiddenImage} />
            )
        } else if (openPopUpGambarSingle) {
            return (
                <Lightbox image={SelectImageSingle} onClose={HiddenImageSingle} />
            )
        } else return null
    }, [openPopUpGambar, openPopUpGambarSingle, SelectImage, SelectImageSingle]);


    const renderTransaksi = useMemo(() => {
        return (
            <>
                {isLoadRiwayat ? (
                    <Row>
                        {rowLoading.map((el:any, index:number) => {
                            return (
                                <div className="content-full mgTopMin5" key={index + 1}>
                                    <div className="container">

                                        <div className="card bg-dark mt-4">
                                            <Skeleton width={110} height={118} className="card-img-top" />

                                            <div className="card-body">
                                                <div className="text-section">
                                                    <h5 className="card-title fw-bold text-white"> <Skeleton height={10} /></h5>
                                                    <p className="card-text text-white"><Skeleton height={10} /></p>
                                                    <p className="card-text text-white"><Skeleton height={10} width={60} /></p>
                                                </div>
                                                <div className="cta-section">
                                                    <div className="text-white"><Skeleton height={10} width={60} /></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            )
                        })
                        }
                    </Row>
                ) : (
                    <Row>
                        {listRiwayat.map((item:any, index:number) => {
                            const image = item.lokasifotoonline ? item.lokasifotoonline : NoImage;
                            return (
                                    <div className="content-full mgTopMin5" key={index + 1}>
                                        <div className="container">

                                            <div className="card bg-dark mt-4">
                                                <img src={image} className="card-img-top" alt="..." onClick={ShowImage.bind(this, item.namabarang, item.listGambar)} />
                                                <div className="card-body">
                                                    <div className="text-section">
                                                        <p className="card-text text-white">{moment(item.tanggal).format("DD-MM-YYYY HH:mm")}</p>

                                                        <p className="card-text text-white">{item.namabarang}</p>
                                                        <p className="card-text text-white">Rp. {numberWithCommas(item.harga)}</p>
                                                    </div>
                                                    <div className="cta-section">
                                                        <div className="text-white">{item.notrans}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                            )
                        })}
                    </Row>
                )}

            </>
        )
    }, [isLoadRiwayat, listRiwayat, ShowImage])



    return (
        <React.Fragment>
            {renderTransaksi}
            {renderPopUpImage}
            <div className="mgBottom"></div>
        </React.Fragment>
    )
}

export default Riwayat;