let ConfigCompany;
if (process.env.REACT_APP_BUILD.toUpperCase() === "UTAMA") {
  ConfigCompany = {
    URL_API: process.env.REACT_APP_API_UTAMA,
    URL_DOMAIN: process.env.REACT_APP_DOMAIN_UTAMA,
    COMPANY_ID: process.env.REACT_APP_COMPANYID_UTAMA,
    COMPANY: process.env.REACT_APP_COMPANY_UTAMA,
    COMPANY_NAME: process.env.REACT_APP_NAME_UTAMA,
    firebase: {
      apiKey: "AIzaSyD-QO2NrUZNNOgqOpRE7EavXKcGx0oU0ik",
      authDomain: "homanerp-ef9de.firebaseapp.com",
      projectId: "homanerp-ef9de",
      storageBucket: "homanerp-ef9de.appspot.com",
      messagingSenderId: "150752921958",
      appId: "1:150752921958:web:2cdbfbd2053b9b526ee0a7",
      measurementId: "G-FT9JT6J7W4"
    }
  };
} else if (process.env.REACT_APP_BUILD.toUpperCase() === "TESTER") {
  ConfigCompany = {
    URL_API: process.env.REACT_APP_API_TESTER,
    URL_DOMAIN: process.env.REACT_APP_DOMAIN_TESTER,
    COMPANY_ID: process.env.REACT_APP_COMPANYID_TESTER,
    COMPANY: process.env.REACT_APP_COMPANY_TESTER,
    COMPANY_NAME: process.env.REACT_APP_NAME_TESTER,
    firebase: {
      apiKey: "AIzaSyD-QO2NrUZNNOgqOpRE7EavXKcGx0oU0ik",
      authDomain: "homanerp-ef9de.firebaseapp.com",
      projectId: "homanerp-ef9de",
      storageBucket: "homanerp-ef9de.appspot.com",
      messagingSenderId: "150752921958",
      appId: "1:150752921958:web:2cdbfbd2053b9b526ee0a7",
      measurementId: "G-FT9JT6J7W4"
    }
  };
} 
export default ConfigCompany;
