
import React, { useState, useMemo,useContext,useEffect, useCallback } from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormChangeProfile from "./UbahProfile";
import FormChangePassword from "./UbahPassword";
import mAuth from '../../Controller/auth'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { detailProfile } from "../../interfaces/Akun";

const rowLoading = [] as any;
for (let i:number = 0; i <= 14; i++) {
    rowLoading.push(i);
}

const Home = (props:any) => {
    const { clientID, token,email } = useContext<any>(AuthContext);

    const [detail, setDetail] = useState<detailProfile>({});
    const [isLoadDetail, setIsLoadDetail] = useState<boolean>(true);

    const [isOpenChangeProfile, setIsOpenChangeProfile] = useState<boolean>(false);
    const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);

    useEffect(() => {
        if (isLoadDetail) {
            mAuth
            .detailUserUserMemberApp(clientID, token,email )
            .then(resp => {
                setDetail(resp);
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => setIsLoadDetail(false));
        }
    
        return () => {
    
        }
      }, [isLoadDetail,clientID, token,email ])

      const handleReload = useCallback(() => {
        setIsLoadDetail(true);
    },[]);

    const renderOpenChangeProfile = useMemo(() => {
        if (isOpenChangeProfile) {
            return (
                <FormChangeProfile open={isOpenChangeProfile} setOpen={setIsOpenChangeProfile} detail={detail} clientID={clientID} token={token} handleReload={handleReload}/>
            )
        } else return null;
    }, [isOpenChangeProfile,detail,clientID,token,handleReload]);

    const renderOpenChangePassword = useMemo(() => {
        if (isOpenChangePassword) {
            return (
                <FormChangePassword open={isOpenChangePassword} setOpen={setIsOpenChangePassword} detail={detail} clientID={clientID} token={token} handleReload={handleReload}/>
            )
        } else return null;
    }, [isOpenChangePassword,detail,clientID,token,handleReload]);


    const renderTableProfile = useMemo(() => {
        return (
            <Container>


                <CssBaseline />
                <div className="paper">
                <Card >
                <CardContent>
                    <div className="mgTop10">
                        <table className="table table-bordered table-akun">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th className="tbakun-right">{detail.email}</th>
                                </tr>
                                <tr>
                                    <th>No HP</th>
                                    <th className="tbakun-right">{detail.nohp}</th>
                                </tr>
                                <tr>
                                    <th>Nama</th>
                                    <th className="tbakun-right">{detail.Namacust}</th>
                                </tr>

                                <tr>
                                    <th>Alamat</th>
                                    <th className="tbakun-right">{detail.alamat}</th>
                                </tr>
                            </thead>
                        </table>

                        <div className="mgTop10">
                            <Row>
                                <Col xs={6}>
                                    <Button
                                        onClick={() => { setIsOpenChangeProfile(true) }}
                                        fullWidth
                                        variant="contained"
                                        className="submit"
                                    >
                                        Ubah Profile
                                    </Button>
                                </Col>

                                <Col xs={6}>
                                    <Button
                                        onClick={() => { setIsOpenChangePassword(true) }}
                                        fullWidth
                                        variant="contained"
                                        className="btnUbahPass"
                                    >
                                        Ubah Password
                                    </Button>
                                </Col>

                            </Row>
                        </div>
                    </div>


              </CardContent>
                    </Card>

                </div>

            </Container>
        )
    }, [detail])



    return (
        <React.Fragment>
            {renderTableProfile}
            {renderOpenChangeProfile}
            {renderOpenChangePassword}
        </React.Fragment>
    )
}

export default Home;