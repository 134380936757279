import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import './Desain/General.css'
import * as serviceWorker from "./serviceWorker";
require(`./Desain/${process.env.REACT_APP_BUILD === "UTAMA" ? process.env.REACT_APP_COMPANY_UTAMA : process.env.REACT_APP_COMPANY_TESTER}/index.css`);
require(`./Desain/produk.css`);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <>
    <App />
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    console.log("New version available!");
    // alert("New version available!  Ready to update?");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  }
});

