import React, { useState, useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Row, Col } from "reactstrap";

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const ModalFilter = (props) => {
  const { listDataOri, selectedKategori, setSelectedKategori, selectedSubkategori, setSelectedSubkategori } = props;
  const [kategori, setKategori] = useState([]);
  const [subkategori, setSubkategori] = useState([]);

  useEffect(() => {
    if (listDataOri.length > 0) {
      const kategoriList = Array.from(new Set(listDataOri.map((item) => item.namakategori)));
      setKategori(kategoriList);
    }

    return () => {

    }
  }, [listDataOri])

  useEffect(() => {
    if (selectedKategori !== '') {
      const subkategoriList = Array.from(new Set(listDataOri.filter((item) => item.namakategori === selectedKategori).map((item) => item.namasubkategori)));
      setSubkategori(subkategoriList);
    }
  }, [selectedKategori, listDataOri]);

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleKategoriChange = (event) => {
    setSelectedKategori(event.target.value);
    setSelectedSubkategori('');
  };

  const handleSubkategoriChange = (event) => {
    setSelectedSubkategori(event.target.value);
  };
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Filter
      </DialogTitle>
      <DialogContent dividers>
        <Row>
          <Col xs={12}>
            <FormControl fullWidth className="margin-top-mobile">
              <InputLabel id="demo-simple-select-label">
                Pilih Kategori
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedKategori}
                onChange={handleKategoriChange}
              >
                {kategori.map((el, i) => {
                  return (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12}>
            <FormControl fullWidth className="margin-top-mobile">
              <InputLabel id="demo-simple-select-label">
                Pilih Sub Kategori
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSubkategori}
                onChange={handleSubkategoriChange}
              >
                {subkategori.map((el, i) => {
                  return (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  )
}
export default ModalFilter;
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
