import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from "reactstrap";
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import mAuth from '../../Controller/auth'
import DialogInfo from '../../Dialog/DialogInfo'
import { formUbahPassword } from "../../interfaces/Akun";

const styles = (theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });


const Form = (props) => {
    const [dialogtext, setDialogtext] = useState<string>();
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState<boolean>(false)

    const [form, setForm] = useState<formUbahPassword>({
        email: props?.detail?.email,
        password: '',
        password2: '',
    });


    const handleClose = () => {
        props.setOpen(false);
    };

    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...form };
        newValue[key] = value;
        setForm(newValue);
    }

    const handleReset = (ev) => {
        const {clientID,token} = props;
        if (form.password !== form.password2) {
            setOpenConfirmInfo(true)
            setDialogtext(`<p>Password yang anda masukan harus sama<p/>`)
            return
        }
            mAuth.resetPassword(clientID,token,form.email, form.password).then((res) => {
                if (res) {
                    setOpenConfirmInfo(true);
                    setDialogtext(`<p>Berhasil Update Password<p/>`);
                    props.handleReload();
                    setTimeout(() => {
                        handleClose();
                    }, 3000);
                } else {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${res}<p/>`)
                }
            }).catch(err => {
                console.log(err);
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${err[0].message}<p/>`)
            })
            ev.preventDefault();
        
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle onClose={handleClose}>
                Ubah Profile
            </DialogTitle>
            <DialogContent dividers>
                <Row>
                    <Col xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Masukan Password Baru Anda"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={form.password}
                            onChange={handleChange}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password2"
                            label="Ulangi Masukan Password"
                            type="password"
                            id="password2"
                            autoComplete="current-password2"
                            value={form.password2}
                            onChange={handleChange}
                        />
                        <Button
                            // type="submit"
                            fullWidth
                            variant="contained"
                            className="submit"
                            onClick={handleReset}
                        >Ubah
                        </Button>


                    </Col>
                </Row>
            </DialogContent>

            {OpenConfirmInfo && (
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext}  />
            )}
        </Dialog>
    )
}
export default Form;

const DialogTitle = withStyles(styles)((props:any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
