import Company from "../settings/Company";
import GQL from '../settings/GQL';
import { listTable} from "../interfaces/Home";

const Home = {
  listSlider: () => {
    return new Promise<listTable[]>((resolve, reject) => {
      const query = `
                    query{
                        listSlider_memberApp(perusahaanID: ${Company.COMPANY_ID}){
                          urut: nourut
                          notrans
                          namafile
                          extension
                          linkURL
                        }
                    }
                `
      GQL(query, '', '').then(resp => {
        if (resp.errors) return (reject(resp.errors));;
        if (resp && resp.data && resp.data.listSlider_memberApp) {
          return (resolve(resp.data.listSlider_memberApp));
        } else {
          return (resolve([]));
        }
      }).catch(err => reject(err));
    })
  },
}
export default Home;