import React, { useState, useContext } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AuthContext from '../../../context/auth-context';
import ForgetPassword from '../../../Dialog/ForgetPassword';
import DialogInfo from '../../../Dialog/DialogInfo';

const MadeWithLove = () => {
  if (process.env.REACT_APP_COMPANY === "ipos") {
    // eslint-disable-next-line 
    return (
      <Typography variant="body2" align="center">
        Developed by <a color="inherit" className="colorLogin" target="_blank" href="ipos.co.id">iPOS</a> team
      </Typography>
    );
  } else return null

}


const Login = (props) => {

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [isForget, setIsForget] = useState(false)
  const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
  const [dialogtext, setDialogtext] = useState()
  const { login } = useContext(AuthContext);

  const handleUsername = (val) => setUsername(val.target.value);

  const handlePassword = (val) => setPassword(val.target.value);

  const handleLogin = (ev) => {
    let usernameNew
    if (username.substring(0, 1) === "0") {
      usernameNew = `62${username.substring(1)}`
    } else if (username.substring(0, 3) === "+62") {
      usernameNew = username.substring(1)
    } else usernameNew = username
    login(usernameNew, password);

    ev.preventDefault();
  }
  const handleForget = () => {
    setIsForget(true)
  }
  const handleForgetInfo = (info) => {
    setIsForget(false)
    setOpenConfirmInfo(true)
    setDialogtext(info)
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper">
        <Avatar className="avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className="form" onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="E-mail"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleUsername}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePassword}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="submit"
          >
            Masuk
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="#" className="colorLogin" onClick={handleForget} variant="body2">
                Lupa password?
              </Link>
            </Grid>
            <Grid item>
              <Link className="colorLogin" to="/register" variant="body2">
                {"Belum punya akun? Daftar"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
      {isForget && (
        <ForgetPassword open={isForget} setOpen={setIsForget} info={handleForgetInfo} />
      )}
      {OpenConfirmInfo && (
        <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
      )}
    </Container>
  );
}

export default Login;