import React from 'react';

export default React.createContext({
  clientID: null,
  clientID2: null,
  token: null,
  userLogin : null,
  hp: null,
  email: null,
  login: (username,password) => {},
  logout: ()=>{}
})