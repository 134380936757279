import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import DialogInfo from '../../Dialog/DialogInfo'
import GQL from '../../settings/GQL';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';



const ResetPassword = (props) => {
    const email = props.match.params.email
    const salt = props.match.params.salt
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [dialogUrl, setDialogUrl] = useState()
    const [password, setPassword] = useState()
    const handleChange = (ev) => {
        setPassword(ev.target.value)
    }
    const handleReset = () => {
        const query = `
        mutation{
            resetPassword(salt:"${salt}",email:"${email}", password:"${password}"){
                success
                info
            }
          }
        `
        GQL(query, null, null).then(res => {
            if (res.errors) throw res.errors;
            if (res.data.resetPassword.success) {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${res.data.resetPassword.info}<p/>`)
                setDialogUrl(`/login/`)

            } else {
                setOpenConfirmInfo(true)
                setDialogtext(`<p>${res.data.resetPassword.info}<p/>`)
            }
        }).catch(err => {
            setOpenConfirmInfo(true)
            if (err[0].message) {
                setDialogtext(`<p>${err[0].message}<p/>`)
                setDialogUrl(`/login/`)
            } else {
                alert("Tidak dapat menjalankan permintaan Anda saat ini. Mohon coba lagi nanti")
            }
        })
    }
    return (
        <Row>
            <Col xs={12}>
                <h6 className="text-center">Hello {email}</h6>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Masukan Password Baru Anda"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChange}
                />
                <Button
                    fullWidth
                    variant="contained"
                    className="submit"
                    onClick={handleReset}
                >
                    Reset Password
          </Button>
            </Col>
            {OpenConfirmInfo && (
                <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} url={dialogUrl} redirect={props.history.push} />
            )}
        </Row>
    );
}

export default ResetPassword;